import { useEffect, useRef, useState } from 'react'
import { RiDeleteBin6Line, RiEdit2Line } from 'react-icons/ri'

import {
  AddButton,
  AddNewsModal,
  AdminPageModel,
  DeleteNewsModal,
  EditNewsModal,
  Spinner
} from 'components'
import { INews } from '../../../entities'
import { useGetNews } from 'hooks'

import {
  EmptyListMessage,
  TitlePage,
  WrapperContent,
  ModalWrapper,
  WrapperNewsList
} from '../styles'

export const NewsAdmin = () => {
  const refDragItem = useRef<any>(null)
  const refDragOverItem = useRef<any>(null)
  const refAddModal = useRef<HTMLDialogElement>(null)
  const refEditModal = useRef<HTMLDialogElement>(null)
  const refDeleteModal = useRef<HTMLDialogElement>(null)

  const [newsSelected, setNewsSelected] = useState<INews | null>(null)

  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  const { news, isLoading, setOrderNewsList, getNews } = useGetNews({
    isOpenAddModal,
    isOpenDeleteModal,
    isOpenEditModal
  })

  const toggleAddModal = () => {
    if (refAddModal.current) {
      if (isOpenAddModal) {
        setIsOpenAddModal(false)
        refAddModal?.current.close()
        return
      }
      setIsOpenAddModal(true)
      refAddModal?.current.showModal()
    }
  }

  const toggleEditModal = () => {
    if (refEditModal.current) {
      if (isOpenEditModal) {
        setIsOpenEditModal(false)
        refEditModal?.current.close()
        return
      }
      setIsOpenEditModal(true)
      refEditModal?.current.showModal()
    }
  }

  const toggleDeleteModal = () => {
    if (refDeleteModal.current) {
      if (isOpenDeleteModal) {
        setIsOpenDeleteModal(false)
        refDeleteModal?.current.close()
        return
      }
      setIsOpenDeleteModal(true)
      refDeleteModal?.current.showModal()
    }
  }

  const handleSort = () => {
    if (refDragItem.current === refDragOverItem.current) {
      return
    }

    const _news = [...news]
    const draggedItemContent = _news.splice(refDragItem?.current as unknown as number, 1)[0]
    _news.splice(refDragOverItem.current as unknown as number, 0, draggedItemContent)

    refDragItem.current = null
    refDragOverItem.current = null

    setOrderNewsList(_news)
  }

  useEffect(() => {
    if (!isOpenAddModal && !isOpenDeleteModal && !isOpenEditModal) {
      getNews()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminPageModel newsListLength={news.length} isLoadingNews={isLoading}>
      <WrapperContent>
        <TitlePage>Destaques</TitlePage>
        <p>
          Adicione os destaques que irão aparecer na tela inicial. Você pode adicionar até 10
          destaques.
        </p>
        <AddButton text="Adicionar destaques" onClick={toggleAddModal} />
        {isLoading && <Spinner />}
        {!isLoading && news.length === 0 && (
          <EmptyListMessage>
            <p>Nenhum destaque encontrado!</p>
          </EmptyListMessage>
        )}
        {!isLoading && news.length > 0 && (
          <WrapperNewsList>
            {news.map((el, index) => (
              <div
                key={el.id}
                className="news-item"
                draggable
                onDragStart={(e) => {
                  refDragItem.current = index
                }}
                onDragEnter={(e) => {
                  refDragOverItem.current = index
                }}
                onDragEnd={() => {
                  handleSort()
                }}
                onDragOver={(e) => {
                  e.preventDefault()
                }}
              >
                <div className="icons">
                  <button
                    onClick={() => {
                      setNewsSelected(el)
                      toggleEditModal()
                    }}
                    title="Editar destaque"
                  >
                    <RiEdit2Line />
                  </button>
                  <button
                    onClick={() => {
                      setNewsSelected(el)
                      toggleDeleteModal()
                    }}
                    title="Remover destaque"
                  >
                    <RiDeleteBin6Line />
                  </button>
                </div>
                <img
                  src={el.imageUrlData}
                  alt="preview da imagen"
                  className="news-item__img"
                  loading="lazy"
                />
                <div className="text" dangerouslySetInnerHTML={{ __html: el.description }}></div>
              </div>
            ))}
          </WrapperNewsList>
        )}
      </WrapperContent>
      <ModalWrapper ref={refAddModal}>
        {isOpenAddModal && (
          <AddNewsModal
            toggleModal={toggleAddModal}
            listLength={news.length}
            getNews={() => {
              getNews()
            }}
          />
        )}
      </ModalWrapper>

      <ModalWrapper ref={refEditModal}>
        {isOpenEditModal && newsSelected && (
          <EditNewsModal
            toggleModal={toggleEditModal}
            newsSelected={newsSelected}
            getNews={() => {
              getNews()
            }}
          />
        )}
      </ModalWrapper>

      <ModalWrapper ref={refDeleteModal}>
        {isOpenDeleteModal && newsSelected && (
          <DeleteNewsModal
            toggleModal={toggleDeleteModal}
            newsSelected={newsSelected}
            getNews={() => {
              getNews()
            }}
          />
        )}
      </ModalWrapper>
    </AdminPageModel>
  )
}
