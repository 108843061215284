import { FormI } from '../../../entities'

export const getUserField = (
  data: Record<string, string | File>,
  form: FormI
): Record<string, string> => {
  const userAtt = form.attributes.find((att) => att.type === 'user')
  const question = userAtt?.optionsSelectElement[0].attributes[0].name as string
  return {
    question,
    answer: (data[question] as string) ?? JSON.parse(localStorage.getItem('user') as string).userLogin
  }
}

export const updateUserAnswer = (
  data: Record<string, string | File>,
  question: string,
  userName: string
) => {
  let newData: Record<string, string> = {}
  for (const [key, value] of Object.entries(data)) {
    const field: Record<string, string> = {}
    if (key === question) {
      field[key] = userName
    } else {
      field[key] = value as string
    }
    newData = { ...newData, ...field }
  }
  return newData
}
