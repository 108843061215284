import Switch from 'react-switch'

type Props = {
  isChecked: boolean
  handleChange: (value: boolean) => void
}

const SwitchComponent = ({ handleChange, isChecked }: Props) => {
  return (
    <Switch
      onChange={(e) => { handleChange(e) }}
      checked={isChecked}
      uncheckedIcon={false}
      checkedIcon={false}
      offColor="#ababab"
      onColor="#238ee7"
      handleDiameter={18}
      height={26}
      width={70}
    />
  )
}

export default SwitchComponent
