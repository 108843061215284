import { useLocation } from 'react-router-dom'
import HamburgerMenu from './HamburgerMenu'
import HeaderMenu from './HeaderMenu'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'

import { SizeBreakpoint } from '../../styles/Breakpoints'
import { ContainerHeader } from './styles'

export type LinkProps = {
  title: string
  href: string
}

const menuItems: LinkProps[] = [
  { title: 'Início', href: '/' },
  { title: 'Consulta de Solicitação', href: '/ticket/list' },
  { title: 'Sair', href: '/login' }
]

const Header = () => {
  const { pathname } = useLocation()
  const { width } = useWindowDimensions()
  const user = JSON.parse(localStorage.getItem('user') as string)
  const firstName = user?.userName?.split(' ')[0] ?? ''
  const title = `Olá, ${firstName as string}!`
  // The success page can't show the menu
  const isShowMenu = pathname?.split('/')?.includes('success') ?? false

  if (user?.role === 'ADMIN' && menuItems.length === 3) {
    menuItems.splice(2, 0, { title: 'Administrador', href: '/admin' })
  }
  return (
    <ContainerHeader >
      {!isShowMenu && width > SizeBreakpoint.md ? (
        <HeaderMenu items={menuItems} />
      ) : (
        <HamburgerMenu items={menuItems} />
      )}
      <div className="header-content">
        <h1>{title}</h1>
        <p>Como podemos lhe ajudar?</p>
      </div>
    </ContainerHeader>
  )
}

export default Header
