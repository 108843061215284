import { LegacyRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArticleList } from '..'
import { ArticleModel } from '../../entities'

import { Modal } from './styles'

type Props = {
  refModal: LegacyRef<HTMLDialogElement>
  isModalOpen: boolean
  toggleModal: () => void
  title: string
  articles: ArticleModel[]
}

const ArticleModal = ({
  refModal,
  isModalOpen,
  toggleModal,
  title,
  articles
}: Props) => {
  const navigate = useNavigate()
  return (
    <Modal ref={refModal} className="dialog-faq">
      {isModalOpen && (
        <>
          <div className="modal-header">
            <p>Você escolheu a opção:&nbsp;</p>
            <b>{title}</b>
          </div>
          <div className="modal-body">
            <p>
              Esses artigos podem te ajudar a entender melhor sobre essa opção:
            </p>
            <ArticleList articles={articles} />
          </div>
          <div className="modal-footer">
            <p>Ainda restou alguma dúvida?</p>
            <div>
              <button
                onClick={() => {
                  navigate('/')
                }}
              >
                Não tenho mais dúvidas
              </button>
              <button onClick={toggleModal}>
                Sim, gostaria de abrir um chamado
              </button>
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}

export default ArticleModal
