import { useRef, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
  Breadcrumb,
  ContainerLoading,
  FormGeneric,
  Notification,
  PageModel,
  ArticleModal
} from '../../components'
import { useGetForm } from '../../hooks'
import { ContainerTicketForm } from './styles'

export const TicketForm = () => {
  const { state } = useLocation()
  const { formId } = useParams()
  const { isLoadingForm, form } = useGetForm(formId as string)

  const refModal = useRef<HTMLDialogElement>(null)
  const [isModalOpen, setIsOpenModal] = useState(true)
  const [title, setTitle] = useState('')
  const showForm = !isLoadingForm && !isModalOpen
  const showModal = !isLoadingForm && isModalOpen && (form?.articles && form.articles.length > 0)

  const toggleModal = () => {
    if (refModal.current) {
      if (isModalOpen) {
        setIsOpenModal(false)
        setTitle(form?.parentName as string)
        refModal?.current.close()
        return
      }
      setIsOpenModal(true)
      refModal?.current.showModal()
    }
  }

  const renderComponent = () => {
    if (form?.title) {
      return <FormGeneric form={form} />
    }
    // -- Form in construction---   //
    return <Notification message="Página em construção!" />
  }

  useEffect(() => {
    if (form?.articles.length === 0 && !isLoadingForm) {
      setIsOpenModal(false)
      setTitle(form?.parentName)
      return
    }
    if (showModal && refModal.current) {
      setTitle('Artigos úteis')
      refModal?.current.showModal()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, isLoadingForm, form])

  return (
    <PageModel title={title}>
      <ContainerTicketForm>
        <Breadcrumb
          titlePage="Novo chamado"
          goToHome={!form?.title || state?.goToHome}
          goBackStep={state?.twoStep}
        />
        <div className="ticket-form-content">
          {isLoadingForm && <ContainerLoading />}
          {showForm && renderComponent()}
          {showModal && (
            <ArticleModal
              refModal={refModal}
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
              articles={form?.articles}
              title={form?.parentCard}
            />
          )}
        </div>
      </ContainerTicketForm>
    </PageModel>
  )
}
