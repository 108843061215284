import { ReactNode, useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Footer from 'components/Footer'
import { AuthContext } from 'contexts/auth'
import { useWindowDimensions } from 'hooks'

import logoSebrae from '../../assets/sebrae-logo.png'
import { Container, Header, Sidebar, WrapperContentSidebar } from './styles'

type Props = {
  children: ReactNode
  newsListLength?: number
  isLoadingNews?: boolean
  cardListLength?: number
  isLoadingCard?: boolean
}
const AdminPageModel = ({
  children,
  newsListLength = 0,
  isLoadingNews = false,
  cardListLength = 0,
  isLoadingCard = false
}: Props) => {
  const { logout } = useContext(AuthContext)
  const user = JSON.parse(localStorage.getItem('user') as string)
  const firstName = user?.userName?.split(' ')[0] ?? ''
  const navigate = useNavigate()
  if (user?.role !== 'ADMIN') {
    navigate('/')
  }
  const { width, height } = useWindowDimensions()

  const getHeight = () => {
    let heightScreen = '100vh'
    // News responsive conditions
    if (height >= 1024 && newsListLength >= 5 && !isLoadingNews) {
      heightScreen = '100vh'
      return heightScreen
    }
    if (width >= 1600 && newsListLength > 5 && !isLoadingNews) {
      heightScreen = '100%'
      return heightScreen
    }
    if (width >= 1350 && width < 1600 && newsListLength > 4 && !isLoadingNews) {
      heightScreen = '100%'
      return heightScreen
    }
    if (width === 1350 && newsListLength === 4 && !isLoadingNews) {
      heightScreen = '100%'
      return heightScreen
    }
    if (width >= 1100 && width < 1350 && newsListLength > 3 && !isLoadingNews) {
      heightScreen = '100%'
      return heightScreen
    }
    if (width >= 700 && width < 1100 && newsListLength > 2 && !isLoadingNews) {
      heightScreen = '100%'
      return heightScreen
    }
    if (width >= 500 && width < 700 && newsListLength > 1 && !isLoadingNews) {
      heightScreen = '100%'
      return heightScreen
    }

    // Card responsive conditions
    if (height <= 1200 && cardListLength > 6 && !isLoadingCard) {
      heightScreen = '100%'
      return heightScreen
    }
    return heightScreen
  }

  return (
    <Container $height={getHeight()}>
      <Header>
        <div className="header-logo">
          <img src={logoSebrae} alt="logo do TJ" />
        </div>
        <div className="header-label">
          <p>Dashboard</p>
        </div>
        <div className="header-info">
          <div className="header-info-icon">
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
          <div>
            <p>{`Olá, ${firstName as string}!`}</p>
            <p>Administrador</p>
          </div>
        </div>
      </Header>
      <WrapperContentSidebar>
        <Sidebar>
          <NavLink end to="/admin" className={({ isActive }) => (isActive ? 'active' : '')}>
            Admin
          </NavLink>
          <NavLink end to="/admin/news" className={({ isActive }) => (isActive ? 'active' : '')}>
            Destaques
          </NavLink>
          <NavLink end to="/admin/faq" className={({ isActive }) => (isActive ? 'active' : '')}>
            FAQ
          </NavLink>
          <NavLink end to="/admin/card" className={({ isActive }) => (isActive ? 'active' : '')}>
            Cards
          </NavLink>
          <NavLink to="/">Home</NavLink>
          <NavLink
            to="/login"
            className="link-bottom"
            onClick={() => {
              logout()
            }}
          >
            Sair
          </NavLink>
        </Sidebar>
        <div className="wrapper-content">{children}</div>
      </WrapperContentSidebar>
      <Footer />
    </Container>
  )
}

export default AdminPageModel
