import { FieldValues, UseFormReturn } from 'react-hook-form'
import { AttributeModel, AttributeOptionModel } from '../../../entities'
import { OptionListI } from '../FormGeneric'

export const getCategoryInfoFromSelectedOption = (optionsList: OptionListI[]) => {
  const option = optionsList.find((option) => option.category !== null)
  return { ...option?.category, title: option?.title }
}

const createQuestionsArray = (newList: AttributeModel[], list: AttributeModel[]) => {
  const questions = [] as AttributeModel[]

  newList.forEach((question) => {
    if (!list.find((el) => el.id === question.id)) {
      questions.push(question)
    }
  })

  return questions
}

export const getExtraQuestions = (
  attributes: AttributeModel[],
  previewQuestions: AttributeModel[] = []
) => {
  const questions = previewQuestions
  attributes.forEach((att: AttributeModel) => {
    if (att.optionsSelectElement?.length > 0) {
      att.optionsSelectElement.forEach((option) => {
        if (option.attributes.length > 0) {
          const sortAttributeOptions = option.attributes.sort(
            (a, b) => (a.positionOrder as number) - (b.positionOrder as number)
          )

          const result = createQuestionsArray(sortAttributeOptions, questions)

          questions.push(...result)

          getExtraQuestions(option.attributes, questions)
        }
      })
    }
  })

  return questions
}

type SelectProps = {
  optSelected: string
  attribute: AttributeModel
  optionListSelected: OptionListI[]
  methods: UseFormReturn<FieldValues, any, undefined>
  firstLevel: boolean
}

export const setExtraQuestionsFromSelectField = ({
  optSelected,
  attribute,
  optionListSelected,
  methods,
  firstLevel
}: SelectProps): OptionListI[] => {
  let newOptionListSelected = optionListSelected

  if (optionListSelected.length > 0) {
    // Filter the options list to no repeat the same question
    const optionListDiffAtt = optionListSelected.filter((opt) => opt.attName !== attribute.name)

    // Unregister all the attributes from de same question
    const selectedOption = optionListSelected.find((opt) => opt.attName === attribute.name)

    if (selectedOption && selectedOption.attributes.length > 0) {
      selectedOption.attributes.forEach((el) => {
        methods.unregister(el.name)
      })
    }

    // Filter the options list the first level
    const optionListFirstLevel = optionListDiffAtt.filter((opt) => opt.firstLevel === true)
    newOptionListSelected = optionListFirstLevel

    // Filter the options list the low level
    const optionListLowLevel = optionListDiffAtt.filter((opt) => opt.firstLevel !== true)

    // Unregister all the attributes from de low level option
    if (optionListLowLevel && optionListLowLevel.length > 0) {
      optionListLowLevel.forEach((opt) => {
        if (opt.attributes.length > 0) {
          opt.attributes.forEach((el) => {
            methods.unregister(el.name)
          })
        }
      })
    }
  }

  // Get the data from the option selected
  const attSelected = attribute.optionsSelectElement.find((option) => option.value === optSelected)

  if (attSelected) {
    const newOptionSelect = { ...attSelected, attName: attribute.name, firstLevel }

    return [...newOptionListSelected, newOptionSelect]
  }

  return newOptionListSelected
}

type CheckboxProps = {
  optSelected: AttributeOptionModel
  isChecked: boolean
  optionListSelected: OptionListI[]
  methods: UseFormReturn<FieldValues, any, undefined>
  attName: string
}

export const setExtraQuestionsFromCheckboxField = ({
  optSelected,
  isChecked,
  attName,
  optionListSelected,
  methods
}: CheckboxProps): OptionListI[] => {
  let newOptionListSelected = optionListSelected

  if (isChecked) {
    const newOptionSelect = { ...optSelected, attName }
    return [...newOptionListSelected, newOptionSelect]
  } else {
    newOptionListSelected = newOptionListSelected.filter((op) => op.value !== optSelected.value)
    if (optSelected.attributes.length > 0) {
      optSelected.attributes?.forEach((att) => {
        methods.unregister(att.name)
      })
    }
    return newOptionListSelected
  }
}
