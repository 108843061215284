import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { MenuPlacement, MultiValue, SingleValue } from 'react-select'
import { Select, Spinner } from '../..'
import { AttributeModel, AttributeOptionModel } from '../../../entities'
import { OptionSelect } from 'components/Select/Select'

import { ErrorMessage, FormFieldSelect } from './styles'

type Props = {
  options: AttributeOptionModel[] | OptionSelect[]
  attribute: AttributeModel
  isMulti?: boolean
  handleOptionSelected?: ((value: string) => void) | null
  defaultValue?: OptionSelect[]
  isLoadingFormsAssociated?: boolean
  customSelectStyles?: object | null
  menuPlacement?: MenuPlacement
}

const SelectField = (props: Props) => {
  const {
    options,
    attribute,
    isMulti = false,
    handleOptionSelected = null,
    defaultValue,
    isLoadingFormsAssociated = false,
    customSelectStyles,
    menuPlacement
  } = props
  const {
    control,
    clearErrors,
    formState: { errors }
  } = useFormContext()

  const optionValues: OptionSelect[] = useMemo(() => {
    let _optionValues: any = options
    if (_optionValues.length > 0 && _optionValues[0]?.id) {
      _optionValues = []
      options.forEach((opt) => {
        _optionValues.push({ label: opt.value as string, value: opt.value })
      })
      return _optionValues
    } else {
      return options as OptionSelect[]
    }
  }, [options])

  const handleSelect = (
    e: SingleValue<OptionSelect> | MultiValue<OptionSelect>
  ): string | string[] => {
    clearErrors(attribute.name)
    if (handleOptionSelected && !isMulti) {
      const value = (e as SingleValue<OptionSelect>)?.value
      handleOptionSelected(value as string)
    }
    if (Array.isArray(e)) {
      const values: string[] = []
      e.forEach((element) => {
        values.push(element.value)
      })
      return values
    }
    const value = (e as SingleValue<OptionSelect>)?.value
    return value as string
  }

  const validations = {
    required: {
      value: attribute.required ?? false,
      message: '* Campo obrigatório'
    }
  }

  return (
    <Controller
      name={attribute.name}
      control={control}
      rules={validations}
      render={({ field: { onChange } }) => (
        <FormFieldSelect>
          <label htmlFor={attribute.name}>{attribute.question}</label>
          {isLoadingFormsAssociated && <Spinner size={25} marginTop="1rem" />}
          {!isLoadingFormsAssociated && (
            <Select
              onChange={(e: SingleValue<OptionSelect> | MultiValue<OptionSelect>) => {
                onChange(handleSelect(e))
              }}
              optionValues={optionValues}
              isMulti={isMulti}
              styles={customSelectStyles}
              defaultValue={defaultValue}
              menuPlacement={menuPlacement}
            />
          )}
          {errors[attribute.name] && (
            <ErrorMessage>{errors[attribute.name]?.message?.toString()}</ErrorMessage>
          )}
        </FormFieldSelect>
      )}
    />
  )
}

export default SelectField
