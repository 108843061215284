
type LoginTypeProps = 'AD'

export const useGetUserData = () => {
  const getData = (loginType: string) => {
    if (loginType === 'AD') {
      return JSON.parse(localStorage.getItem('user') as string)
    }
  }
  return {
    getData
  }
}
