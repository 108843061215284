import { ButtonHTMLAttributes } from 'react'
import { ClipLoader } from 'react-spinners'

import { ContainerButton } from './styles'

interface Props extends ButtonHTMLAttributes<HTMLElement> {
  text: string
  isLoading?: boolean
}

const Button = (Properties: Props) => {
  const { isLoading = false, text, ...props } = Properties
  return (
    <ContainerButton type="submit" disabled={isLoading || props?.disabled} {...props}>
      {isLoading ? (
        <ClipLoader
          color="#fff"
          size={25}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <span>{text}</span>
      )}
    </ContainerButton>
  )
}

export default Button
