import { AttributeModel } from '../../entities'

export const fileAttribute: AttributeModel = {
  id: 1,
  name: 'file',
  question: 'Você deseja anexar arquivo? (docx, pdf, png, jpeg ou xls)',
  optionsSelectElement: [],
  required: false,
  isHiddenToVip: false,
  placeholder: '',
  defaultValue: null
}

export const descriptionAttribute: AttributeModel = {
  id: 2,
  name: 'description',
  question: 'Qual seu comentário para o chamado?',
  optionsSelectElement: [],
  required: true,
  isHiddenToVip: false,
  placeholder: '',
  defaultValue: null
}
