/* eslint-disable react-hooks/exhaustive-deps */
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { ArticleI } from './ArticleList'

import { ContainerAccordion } from './styles'
import { useEffect, useState } from 'react'
import axios from 'axios'

type Props = {
  article: ArticleI
  onOpenArticle: (id: string | number) => void
}

const ArticleItem = ({ article, onOpenArticle }: Props) => {
  return (
    <ContainerAccordion $isAccordionOpen={article.isOpen}>
      <div
        className="accordion-toggle"
        onClick={() => {
          onOpenArticle(article.id)
        }}
      >
        <p>{article.title}</p>
        {article.isOpen ? <FaCaretUp /> : <FaCaretDown />}
      </div>
      {article.isOpen && (
        <div className="accordion-content">
          <div className='content' dangerouslySetInnerHTML={{ __html: article?.content }}></div>
          <div className="accordion-content-feedback">
            <p>Este artigo foi útil?</p>
            <div>
              <button onClick={() => { onOpenArticle(article.id) }}>Sim</button>
              <button onClick={() => { onOpenArticle(article.id) }}>Não</button>
            </div>
          </div>
        </div>
      )}
    </ContainerAccordion>
  )
}

export default ArticleItem
