import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ModalContent } from 'components'
import { createNewAdmin } from 'services/api'
import { ModalBodyForm } from 'components/Modal/styles'
import { TextField } from 'components/TicketForms/Fields'

interface IFormInput {
  name: string
  login: string
}

type Props = {
  toggleModal: () => void
  getAdmins: () => void
}

const AddAdminModal = ({ toggleModal, getAdmins }: Props) => {
  const methods = useForm<IFormInput>()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await createNewAdmin(data.name, data.login)
      if (!response) {
        throw new Error()
      }
      setIsLoading(false)
      getAdmins()
    } catch (error) {
      setIsLoading(false)
      toast.error('Ops 😥, ocorreu um erro ao adicionar um novo administrador')
    } finally {
      methods.reset()
      toggleModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Novo administrador"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <ModalBodyForm>
          <TextField
            attribute={{
              id: 1,
              name: 'name',
              question: 'Digite o nome:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              defaultValue: null
            }}
          />
          <TextField
            attribute={{
              id: 2,
              name: 'login',
              question: 'Digite o login:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              defaultValue: null
            }}
          />
        </ModalBodyForm>
      </ModalContent>
    </FormProvider>
  )
}

export default AddAdminModal
