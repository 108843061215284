import styled from 'styled-components'
import image_bgtop from '../../assets/bck-login.jpg'

export const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efefef url(${image_bgtop}) 0% 0% no-repeat padding-box;
  background-size: 100% 48%;
`
export const ErrorMessage = styled.span`
  display: flex;
  align-items: center;
  width: 100% !important;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.red._500};
  font-size: 0.75rem;
  font-weight: 500;
`

export const WrapperLogin = styled.div`
  width: 30rem;
  min-width: 28rem;
  height: fit-content;
  min-height: 27rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 1.7rem;
  padding: 3.4rem 4rem;
  gap: 1.2rem;

  .login-logo {
    width: 18rem;
  }


  .login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
    margin-top: 0.8rem;

    .form-field {
      svg {
        width: 1rem;
        height: 1rem;
        margin: 0.5em 0.5em 0.5em 0.9em;
        color: ${({ theme }) => theme.colors.blue._500};
        position: absolute;
      }

      .user-icon {
        width: 0.875rem;
        height: 0.875rem;
      }

      input {
        width: 100%;
        height: 2rem;
        font: italic normal normal 12px/14px Montserrat;
        background: ${({ theme }) => theme.colors.white};
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colors.blue._500};
        border-left: 4px solid ${({ theme }) => theme.colors.blue._500};
        padding-left: 2.2rem;
        &::placeholder {
          font: italic normal normal 12px/15px Montserrat;
          color: ${({ theme }) => theme.colors.gray._450};
          opacity: 1;
        }

        &:focus {
          outline: 1px solid ${({ theme }) => theme.colors.blue._500};
        }
      }

      .wrapper-input-email {
        display: flex;

        input {
          padding-right: 10rem;
        }

        .suffix {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: absolute;
          width: 1rem;
          height: 1rem;
          margin: 0.5em 0.5em 0.5em 0.5em;
          color: ${({ theme }) => theme.colors.gray._500};
          position: absolute;
          margin-left: 13.5rem;
          margin-top: 0.5rem;
          font-size: 0.75rem;

          font: italic normal normal 12px/15px Montserrat;

          @media (max-width: 768px) {
            margin-left: 13rem;
          }
        }

        .line {
          display: flex;
          width: 1px;
          height: 20px;
          background-color: ${({ theme }) => theme.colors.gray._400};
          position: absolute;
          margin-left: 12.5rem;
          margin-top: 0.35rem;
        }
      }
    }
  }

  .login-error {
    display: flex;
    width: 100% !important;
    align-items: center;
    justify-content: center;
    font-family: Monteserrat;
    color: ${({ theme }) => theme.colors.red._500};
  }
`
