import styled, { css } from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin, breakQueriesMin } from '../../styles/Breakpoints'

const widthLink = '28rem'
const heightLink = '4.5rem'
const mb = '0.25rem'

const btnSize = {
  lg: '2.81rem',
  md: '2.35rem',
  sm: '2rem'
}

const heightWrapperForTwoRows = css`
  height: 13.85rem;
  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.xsm)} {
    height: 12rem;
  }
  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    height: 12.5rem;
  }
  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    height: 12.75rem;
  }
`

const heightWrapperForOneRow = css`
  height: calc(13.85rem / 2);
  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.xsm)} {
    height: calc(12rem / 2);
  }
  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    height: calc(12.5rem / 2);
  }
  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    height: calc(12.75rem / 2);
  }
`

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  margin-top: 1.8rem;
  color: ${({ theme }) => theme.colors.blue._800};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;

  span {
    background-color: ${({ theme }) => theme.colors.blue._800};
    height: 2px;
    width: 2rem;
  }

  @media screen and (max-width: 1280px) and (max-height: 768px) {
    margin-bottom: 0.5rem;
  }
`

//* ****************************** */
//  Styles> TicketList component
//* ****************************** */
export const ContainerTickets = styled.div`
  display: flex;
  flex-direction: column;
  width: ${widthLink};

  .loading {
    display: flex;
    flex-direction: row;
    align-items: center;

    h4 {
      margin-left: 1rem;
    }
  }

  .ticket-item:last-child {
    margin-bottom: 0;
  }

  .ticket-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: ${heightLink};
    height: ${heightLink};
    padding: 0.625rem 1.5rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0.875rem;
    margin-bottom: ${mb};
    cursor: pointer;

    &:hover {
      background: ${({ theme }) => theme.colors.gray._400};
    }

    .ticket-item-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
      color: ${({ theme }) => theme.colors.gray._500};

      span:nth-child(1) {
        font-size: 0.75rem;
        margin-bottom: 4px;
      }

      span:nth-child(2) {
        font-size: 0.875rem !important;
        margin-bottom: 4px;
        font-weight: bold;
      }
    }

    .ticket-item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.75rem;
      height: 1.75rem;
      margin-left: 0.25rem;
      background-color: ${({ theme }) => theme.colors.blue._800};
      border-radius: 50%;

      svg {
        font-size: 3.75rem;
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  ${breakQueriesMaxMin(1320, SizeBreakpoint.xl)} {
    .ticket-item {
      padding: 0.6rem 1.5rem;
      .ticket-item-info {
        span:nth-child(2) {
          font-size: 0.75rem !important;
          margin-bottom: 4px;
          font-weight: bold;
        }
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    .ticket-item {
      padding: 0.6rem 1.5rem;
      .ticket-item-info > span:nth-child(2) {
        font-size: 0.75rem !important;
        margin-bottom: 4px;
        font-weight: bold;
      }
    }
  }
  ${breakQueriesMaxMin(1050, SizeBreakpoint.md)} {
    .ticket-item {
      padding: 0.5rem 0.75rem;
      .ticket-item-info > span:nth-child(2) {
        font-size: 0.7rem !important;
        margin-bottom: 4px;
        font-weight: bold;
      }
    }
  }

  ${breakQueriesMaxMin(910, SizeBreakpoint.md)} {
    .ticket-item {
      padding: 0.5rem 0.5rem;
      .ticket-item-info > span:nth-child(2) {
        font-size: 0.65rem !important;
        margin-bottom: 4px;
        font-weight: bold;
      }
    }
  }

  @media (max-height: 768px) {
    .ticket-item {
      padding: 0.5rem 1.5rem;
    }
    .ticket-item-info > span:nth-child(2) {
      font-size: 0.75rem !important;
      margin-bottom: 4px;
      font-weight: bold;
    }
  }
`

export const TicketStatus = styled.span<{ $statusColor: string }>`
  font-size: 0.75rem;
  font-weight: bold;
  color: ${(props) => props.$statusColor ?? props.theme.colors.gray._500};
`

//* ****************************** */
//  Styles> NewsCarousel component
//* ****************************** */

export const ContainerNewsCarousel = styled.div`
  display: flex;
  flex-direction: column;
  width: ${widthLink};

  .newList-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0.875rem;
    min-height: calc((${heightLink} * 4) + (${mb} * 3));
    height: calc(${heightLink} * 4);
  }

  // Class from nuka-carousel lib
  .slider-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100% !important;
    padding: 0.75rem 0.75rem;

    .carousel-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100% !important;

      img {
        border-radius: 1.5rem;
        width: 90%;
        height: 12.5rem;
        cursor: pointer;
      }

      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 92%;
        text-align: left;
        margin-top: 0.25rem;
        margin-left: 0.7rem;
        font-size: 0.875rem;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.blue._800};
      }
    }

    .slider-control-bottomcenter {
      margin-top: auto !important;
      .paging-item {
        margin-left: 0.875rem;
        .paging-dot {
          width: 0.875rem;
          height: 0.875rem;
          opacity: 1;
          border: 1px solid ${({ theme }) => theme.colors.blue._500};
          border-radius: 1.25rem;
          fill: none;
          background-color: ${({ theme }) => theme.colors.blue._500};
        }
      }
    }
  }

  // Class from nuka-carousel lib - end
  ${breakQueriesMaxMin(1320, SizeBreakpoint.xl)} {
    width: 25rem;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, 1100)} {
    width: 23rem;
  }
  ${breakQueriesMaxMin(1100, 910)} {
    width: 19rem;
    .slider-container {
      padding: 0.75rem 0.5rem;
    }
  }

  ${breakQueriesMaxMin(910, SizeBreakpoint.md)} {
    width: 17rem;
    .slider-container {
      padding: 0.75rem 0.5rem;
    }
  }

  ${breakQueriesMaxMin(810, SizeBreakpoint.md)} {
    width: 15rem;
    .slider-container {
      padding: 0.75rem 0.5rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    .paging-item {
      margin-left: 0.75rem;
      .paging-dot {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }

  @media (max-height: 768px) {
    a {
      font-size: 0.875rem;
    }
  }
`

//* ****************************** */
//  Styles> Contacts component
//* ****************************** */
export const ContainerContacts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: ${widthLink};

  .contacts-content {
    display: flex;
    flex-direction: column;
    border-radius: 0.875rem;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 1.1rem 1.2rem;
    min-height: calc((${heightLink} * 4) + (${mb} * 3));
    height: calc(${heightLink} * 4);
    // height: 100%;

    .title-desk {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font: normal normal normal 13px/23px Montserrat;
        font-size: 0.875rem;
      }
    }

    .wrapper-channels {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .contacts-teams {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        img {
          margin-left: -0.35rem;
          margin-top: 0.8rem;
        }

        a {
          color: ${({ theme }) => theme.colors.blue._800};
        }
        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }

      .contacts-phone {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        color: ${({ theme }) => theme.colors.blue._800};
        font-weight: bold;
        font-size: 0.800rem;

        span {
          display: flex;
          align-items: center;
          margin-right: 1rem;
          margin-left: 0.8rem;
          margin-top: 1.4rem;
          }
        }
      }
    }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    .contacts-content {
      padding: 1rem 1rem;
    }

    .contacts-teams {
      margin-top: -1.6rem;
      font-size: 0.640rem;
      img {
        width: 2.4rem;
      }
    }
    .contacts-phone {
      margin: -0.5rem;
      span {
        font-size: 0.640rem;
    }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    .contacts-content {
      padding: 0.7rem;
    }
    .contacts-teams {
      margin-top: -0.4rem;
      img {
        width:2.5rem;
      }
    }
    .contacts-phone {
      margin: -0.3rem;
      font-size: 0.500rem;
    }
  }
`
//* ******** ********************** */
//  Styles> Carousel component
//* ****************************** */
export const ContainerCarousel = styled.div<{ $isOneRow: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  ${(props) => (props.$isOneRow ? heightWrapperForOneRow : heightWrapperForTwoRows)};
  margin-bottom: ${(props) => (props.$isOneRow ? '2rem' : '0')};
  /* height: 13.85rem; */

  .btn-carousel {
    position: absolute;
    cursor: pointer;
    width: ${btnSize.lg};
    height: 100%;
    background: rgba(255, 255, 255, 0.6) !important;
    border-radius: 0.625rem;
    border: none;
    color: ${({ theme }) => theme.colors.gray._500};

    svg {
      width: 100%;
      height: 3.125rem;
    }
  }

  .btn-carousel-right {
    right: 0;
  }

  .carousel-box--outer {
    width: calc(100% - ${btnSize.lg} * 2);
    height: 100%;
    overflow: hidden;
    position: absolute;
    margin: 0 ${btnSize.lg};
    margin-top: 5px;
    /* overflow-x: scroll; */
  }

  .carousel-box--inner {
    display: grid;
    grid-template-rows: ${(props) =>
    props.$isOneRow ? 'repeat(1, minmax(0, 1fr))' : 'repeat(2, minmax(0, 1fr))'};
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    align-content: center;
    padding-left: 1.125rem;
    gap: 1.25rem;
    height: 100%;
    /* pointer-events: none; */
  }

  /* Media queries */
  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    margin-bottom: ${(props) => (props.$isOneRow ? '1rem' : '0')};
    .btn-carousel {
      width: ${btnSize.sm};
    }

    .carousel-box--outer {
      width: calc(100% - ${btnSize.sm} * 2);
      margin: 0 ${btnSize.sm};
    }

    .carousel-box--inner {
      padding-left: 0.75rem;
      gap: 1rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.sm)} {
    margin-bottom: ${(props) => (props.$isOneRow ? '1rem' : '0')};
    .btn-carousel {
      width: ${btnSize.sm};
    }

    .carousel-box--outer {
      width: calc(100% - ${btnSize.sm} * 2);
      margin: 0 ${btnSize.sm};
    }

    .carousel-box--inner {
      padding-left: 0.75rem;
      gap: 1rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    margin-bottom: ${(props) => (props.$isOneRow ? '1.5rem' : '0')};
    .btn-carousel {
      width: ${btnSize.md};
    }

    .carousel-box--outer {
      width: calc(100% - ${btnSize.md} * 2);
      margin: 0 ${btnSize.md};
    }

    .carousel-box--inner {
      padding-left: 1rem;
      gap: 1.15rem;
    }
  }
`

//* ****************************** */
//  Styles> CardList component
//* ****************************** */
export const ContainerCards = styled.div<{ $isOneRow: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2rem;
  padding-left: 3rem;
  width: 100%;
  height: ${(props) => (props.$isOneRow ? 'fit-content' : '13.85rem')};
  min-height: 6rem;
  margin-bottom: ${(props) => (props.$isOneRow ? '2rem' : '0')};
  gap: 2rem;

  .cards-content {
    display: grid;
    grid-template-rows: ${(props) =>
    props.$isOneRow ? 'repeat(1, minmax(0, 1fr))' : 'repeat(2, minmax(0, 1fr))'};
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    align-content: center;
    row-gap: 1.35rem;
    column-gap: 1.75rem;
    height: 100%;
    width: 100%;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xxl, SizeBreakpoint.xl)} {
    .cards-content {
      column-gap: 0.75rem;
    }
  }

  ${breakQueriesMin(SizeBreakpoint.xxl)} {
    .cards-content {
      column-gap: 3rem;
    }
  }
`
