import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { RiDeleteBin6Line, RiEdit2Line } from 'react-icons/ri'
import {
  AddAdminModal,
  AddButton,
  AdminPageModel,
  DeleteAdminModal,
  EditAdminModal,
  Spinner
} from 'components'
import { getAdminList } from 'services/api'
import { UserAdminModel } from '../../../entities'

import {
  EmptyListMessage,
  ModalWrapper,
  TitlePage,
  WrapperAdminList,
  WrapperContent
} from '../styles'

export const AdminManagement = () => {
  const refAddModal = useRef<HTMLDialogElement>(null)
  const refEditModal = useRef<HTMLDialogElement>(null)
  const refDeleteModal = useRef<HTMLDialogElement>(null)
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  const [adminList, setAdminList] = useState<UserAdminModel[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [adminSelected, setAdminSelected] = useState<UserAdminModel | null>(null)

  const toggleAddModal = () => {
    if (refAddModal.current) {
      if (isOpenAddModal) {
        setIsOpenAddModal(false)
        refAddModal?.current.close()
        return
      }
      setIsOpenAddModal(true)
      refAddModal?.current.showModal()
    }
  }

  const toggleEditModal = () => {
    if (refEditModal.current) {
      if (isOpenEditModal) {
        setIsOpenEditModal(false)
        refEditModal?.current.close()
        return
      }
      setIsOpenEditModal(true)
      refEditModal?.current.showModal()
    }
  }

  const toggleDeleteModal = () => {
    if (refDeleteModal.current) {
      if (isOpenDeleteModal) {
        setIsOpenDeleteModal(false)
        refDeleteModal?.current.close()
        return
      }
      setIsOpenDeleteModal(true)
      refDeleteModal?.current.showModal()
    }
  }

  const getAdmins = async () => {
    try {
      setIsLoading(true)
      const response = await getAdminList()
      if (!response) {
        throw new Error()
      }
      setAdminList(response)
    } catch (error) {
      toast.error('Ops 😥, ocorreu um erro ao carregar os administradores')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!isOpenAddModal && !isOpenEditModal && !isOpenDeleteModal) {
      getAdmins()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminPageModel>
      <WrapperContent>
        <TitlePage>Administrador</TitlePage>
        <AddButton onClick={toggleAddModal} text="Adicionar novo administrador" />
        <WrapperAdminList>
          <label>Administradores:</label>

          {isLoading && <Spinner />}
          {!isLoading && adminList.length === 0 && (
            <EmptyListMessage>
              <p>Nenhum administrador encontrado!</p>
            </EmptyListMessage>
          )}
          {!isLoading && adminList.length > 0 && (
            <table className="admin-list-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {adminList.map((admin) => (
                  <tr key={admin.id}>
                    <td>{admin.name}</td>
                    <td>
                      <button
                        onClick={() => {
                          setAdminSelected(admin)
                          toggleEditModal()
                        }}
                      >
                        <RiEdit2Line />
                      </button>
                      <button
                        onClick={() => {
                          setAdminSelected(admin)
                          toggleDeleteModal()
                        }}
                      >
                        <RiDeleteBin6Line />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </WrapperAdminList>
      </WrapperContent>
      <ModalWrapper ref={refAddModal}>
        {isOpenAddModal && <AddAdminModal toggleModal={toggleAddModal} getAdmins={() => { getAdmins() }} />}
      </ModalWrapper>
      <ModalWrapper ref={refEditModal}>
        {isOpenEditModal && adminSelected && (
          <EditAdminModal toggleModal={toggleEditModal} adminSelected={adminSelected} getAdmins={() => { getAdmins() }} />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refDeleteModal}>
        {isOpenDeleteModal && adminSelected && (
          <DeleteAdminModal toggleModal={toggleDeleteModal} adminSelected={adminSelected} getAdmins={() => { getAdmins() }} />
        )}
      </ModalWrapper>
    </AdminPageModel>
  )
}
