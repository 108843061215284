import styled from 'styled-components'

export const LoadingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 37.25rem;
  height: 23.75rem;
  padding: 1.5rem 3rem;
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.gray._900};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray._300};
  background: ${({ theme }) => theme.colors.white};

  .loading-text {
    font-size: 1.5rem;
    line-height: 1.5;
    margin-top: 2rem;
  }
`
