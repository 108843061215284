type Props = {
  option: string
  quantity: number
  equipments: Record<string, number>
}

export const setEquipmentSelected = ({ option, quantity, equipments }: Props) => {
  const _equipmentsList = Object.keys(equipments)
  let _equipments: Record<string, number> = {}
  const _newEquipment: Record<string, number> = {}

  if (quantity > 0 && _equipmentsList.length === 0) {
    _newEquipment[option] = quantity
    _equipments = { ..._equipments, ..._newEquipment }
  }

  if (quantity > 0 && _equipmentsList.length > 0) {
    const result = filterObjectByOption(option, equipments)
    _newEquipment[option] = quantity
    _equipments = { ...result, ..._newEquipment }
  }
  if (quantity === 0 && _equipmentsList.length > 0) {
    const result = filterObjectByOption(option, equipments)
    _equipments = { ...result }
  }

  return _equipments
}

const filterObjectByOption = (option: string, data: Record<string, number>) => {
  let newData: Record<string, number> = {}
  const _keys = Object.keys(data)
  const _keysFiltered = _keys.filter((opt: string) => opt !== option)

  for (const [key, value] of Object.entries(data)) {
    if (_keysFiltered.includes(key)) {
      const field: Record<string, number> = {}

      field[key] = value

      newData = { ...newData, ...field }
    }
  }
  return newData
}
