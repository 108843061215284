import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin, breakQueriesMin } from 'styles/Breakpoints'

// --------------------------------
// Shared styles
// --------------------------------
export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  min-height: 25rem;
  height: fit-content;
  gap: 1.5rem;

  padding: 2rem 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 2rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.blue._800};
`

export const EmptyListMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: auto;
  margin-top: 3rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blue._500};
`

export const TitlePage = styled.label`
  font-size: 1.15rem;
  font-weight: bold;
  text-transform: uppercase;
`

export const ModalWrapper = styled.dialog<{ $minHeight?: string }>`
  padding: 1.75rem 2.75rem;
  height: fit-content;
  width: 50%;
  min-height: ${(props) => props.$minHeight ?? 'fit-content'};
`

// --------------------------------
// Admin Management styles
// --------------------------------
export const WrapperAdminList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  label {
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .admin-list-table {
    width: 100%;
    border-collapse: collapse;
    text-transform: uppercase;

    thead {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.gray._800};
    }

    tbody {
      color: ${({ theme }) => theme.colors.gray._600};
    }
    tr {
      width: 100%;
      height: 3rem;
    }

    td,
    th {
      border-bottom: 2px solid ${({ theme }) => theme.colors.gray._200};
      text-align: center;
    }

    td:first-child,
    th:first-child {
      width: 90%;
    }

    td:last-child,
    th:last-child {
      width: 10%;
      text-align: right;

      button {
        border: none;
        background-color: ${({ theme }) => theme.colors.white};
        cursor: pointer;
        margin-left: 0.5rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          color: ${({ theme }) => theme.colors.gray._600};
        }
      }

      button:first-child {
        &:hover {
          svg {
            font-weight: bold;
            color: ${({ theme }) => theme.colors.blue._600};
          }
        }
      }
      button:last-child {
        &:hover {
          svg {
            font-weight: bold;
            color: ${({ theme }) => theme.colors.red._700};
          }
        }
      }
    }
  }

  ${breakQueriesMaxMin(900, 560)} {
    .admin-list-table {
      td:first-child,
      th:first-child {
        width: 80%;
      }

      td:last-child,
      th:last-child {
        width: 20%;
      }
    }
  }

  ${breakQueriesMaxMin(560, SizeBreakpoint.xsm)} {
    .admin-list-table {
      td:first-child,
      th:first-child {
        width: 75%;
      }

      td:last-child,
      th:last-child {
        width: 25%;
      }
    }
  }
`

// --------------------------------
// News Admin styles
// --------------------------------
export const WrapperNewsList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
  width: 100%;
  height: auto;

  .news-item {
    width: 15rem;
    max-width: 15rem;
    height: 17rem;
    max-height: 17rem;
    border: 2px solid ${({ theme }) => theme.colors.blue._500};
    border-radius: 1rem;

    &:hover {
      cursor: grab;
    }
    .news-item__img {
      width: 100%;
      max-width: 100%;
      height: 9rem;
      border-radius: 1rem 1rem 0 0;
      border: none;
    }

    .text {
      padding: 0.5rem;
      text-align: justify;
      text-justify: inter-word;
    }

    .icons {
      position: relative;

      button {
        position: absolute;
        right: 0;
        border: none;
        border-radius: 0.25rem;
        background-color: ${({ theme }) => theme.colors.gray._100};
        cursor: pointer;
        margin-top: 0.5rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          color: ${({ theme }) => theme.colors.gray._600};
        }

        &:hover {
          svg {
            font-weight: bold;
            color: ${({ theme }) => theme.colors.blue._800};
          }
        }
      }

      button:first-child {
        margin-right: calc(0.5rem + 1.35rem + 0.5rem);
        &:hover {
          svg {
            color: ${({ theme }) => theme.colors.green._600};
          }
        }
      }

      button:last-child {
        margin-right: 0.75rem;
        &:hover {
          svg {
            color: ${({ theme }) => theme.colors.red._700};
          }
        }
      }
    }
  }

  ${breakQueriesMin(2200)} {
    grid-template-columns: repeat(6, 1fr);
    column-gap: 2rem;
  }

  ${breakQueriesMaxMin(1600, 1350)} {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.35rem;
  }

  ${breakQueriesMaxMin(1350, 1100)} {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.25rem;
  }

  ${breakQueriesMaxMin(1100, 700)} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.25rem;
  }

  ${breakQueriesMaxMin(700, 500)} {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 1.25rem;
  }
`

// --------------------------------
// Articles Admin styles
// --------------------------------
export const WrapperArticleList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  label {
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .article-list-table {
    width: 100%;
    border-collapse: collapse;
    /* text-transform: uppercase; */

    thead {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.gray._800};
      text-transform: uppercase;
    }

    tbody {
      color: ${({ theme }) => theme.colors.gray._600};
    }
    tr {
      width: 100%;
      height: 3rem;
    }

    td,
    th {
      border-bottom: 2px solid ${({ theme }) => theme.colors.gray._200};
    }

    td:first-child,
    th:first-child {
      width: 50% !important;
    }
    td:nth-child(2),
    th:nth-child(2) {
      width: 30%;
      padding-left: 1rem;
      text-align: left;
    }

    td:last-child,
    th:last-child {
      width: 20%;
      text-align: center;
    }

    td:first-child {
      padding-left: 1rem;
      text-transform: lowercase;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    td:nth-child(2) {
      text-transform: lowercase;
      .row-form-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis ellipsis !important;
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    td:last-child {
      button {
        border: none;
        background-color: ${({ theme }) => theme.colors.white};
        cursor: pointer;
        margin-left: 0.5rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          color: ${({ theme }) => theme.colors.gray._600};
        }
      }

      button:first-child {
        &:hover {
          svg {
            font-weight: bold;
            color: ${({ theme }) => theme.colors.blue._600};
          }
        }
      }
      button:last-child {
        &:hover {
          svg {
            font-weight: bold;
            color: ${({ theme }) => theme.colors.red._700};
          }
        }
      }
    }
  }

  ${breakQueriesMaxMin(1200, 1024)} {
    td:nth-child(2) {
      width: 300px;
      max-width: 300px !important;
    }
  }

  ${breakQueriesMaxMin(1024, 900)} {
    td:nth-child(2) {
      width: 250px;
      max-width: 250px !important;
    }
  }

  ${breakQueriesMaxMin(900, 780)} {
    td:nth-child(2) {
      width: 200px;
      max-width: 200px !important;
    }
  }

  ${breakQueriesMaxMin(780, SizeBreakpoint.xsm)} {
    td:nth-child(2) {
      width: 150px;
      max-width: 150px !important;
    }
  }
`

// --------------------------------
// Card Admin styles
// --------------------------------
export const WrapperCardList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  label {
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
  }
`
