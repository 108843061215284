/* eslint-disable @typescript-eslint/space-before-function-paren */
import axios from 'axios'
import { toast } from 'react-toastify'
import {
  ArticleListModel,
  ArticleModel,
  CardModel,
  FormAssociatedModel,
  FormListModel,
  FormModel,
  NewsModel,
  UserAdminModel
} from '../entities'

const url = process.env.REACT_APP_API_URL

export const api = axios.create({
  baseURL: url
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      toast.error('Ops 😥, houve um erro com os dados do usuário, por favor fazer login novamente')
      setTimeout(() => {
        sessionStorage.clear()
        localStorage.clear()
        window.location.href = '/login'
      }, 2000)
    }
  }
)

export const getToken = (): string | null => {
  const token = localStorage.getItem('token')
  return token
}

// --------------------
// SESSION APIs
// --------------------
export async function createSession(
  name: string,
  username: string
): Promise<{ token: string, user: any }> {
  const userData = {
    name,
    username
  }
  const response = await api.post('/session', userData)
  return response.data
}

// --------------------
// FORM APIs
// --------------------
export async function getFormByUuid(id: string | number): Promise<FormModel> {
  const response = await api.get(`/form/${id}`, { headers: { Authorization: getToken() } })
  return response.data
}

// export async function getForms(): Promise<FormModel[]> {
//   const response = await api.get('/form', { headers: { Authorization: getToken() } })
//   return response.data
// }

type GetFormsProps = {
  page?: number
  limit?: number
}

export async function getForms ({ page = 0, limit = 5 }: GetFormsProps): Promise<FormListModel> {
  const response = await api.get(`/form?limit=${limit}&page=${page}&order=DESC`, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

// --------------------
// CARD APIs
// --------------------
export async function getCards(): Promise<CardModel[]> {
  const response = await api.get('/card', { headers: { Authorization: getToken() } })
  return response.data
}

export type CreateCardProps = {
  name: string
  icon: string
  color: null | string
  systemTypes: null
  birthOrder: number
  isEnable: boolean
  parent: null | { id: number | string }
  children: []
}

export async function createCard(payload: CreateCardProps): Promise<CardModel> {
  const response = await api.post('/card', payload, { headers: { Authorization: getToken() } })
  return response.data
}

export async function editCard(payload: CardModel): Promise<CardModel> {
  const response = await api.put(`/card/${payload.id}`, payload, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

export async function deleteCard(id: string | number): Promise<any> {
  const response = await api.delete(`/card/${id}`, { headers: { Authorization: getToken() } })
  return response.data
}

export async function updateCardListOrder(payload: CardModel[]): Promise<void> {
  const response = await api.put('/card/order', payload, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

// --------------------
// MANAGEMENT ADMIN APIs
// --------------------
export async function createNewAdmin(name: string, userLogin: string): Promise<any> {
  const userData = {
    name,
    userLogin: userLogin.toLowerCase()
  }
  const response = await api.post('/user', userData, { headers: { Authorization: getToken() } })
  return response.data
}

export async function getAdminList(): Promise<UserAdminModel[]> {
  const response = await api.get('/user', { headers: { Authorization: getToken() } })
  return response.data
}

export async function EditAdmin(
  name: string,
  userLogin: string,
  id: string | number
): Promise<any> {
  const userData = {
    name,
    userLogin: userLogin.toLowerCase()
  }
  const response = await api.put(`/user/${id}`, userData, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

export async function DeleteAdmin(id: string | number): Promise<any> {
  const response = await api.delete(`/user/${id}`, { headers: { Authorization: getToken() } })
  return response.data
}
// --------------------
// NEWS ADMIN APIs
// --------------------
export async function createNews(
  description: string,
  urlPicture: string,
  weight: number
): Promise<any> {
  const newsData = {
    description,
    url_picture: urlPicture,
    weight
  }
  const response = await api.post('/news', newsData, { headers: { Authorization: getToken() } })
  return response.data
}

export async function getNewsList(): Promise<NewsModel[]> {
  const response = await api.get('/news', { headers: { Authorization: getToken() } })
  return response.data
}
export async function deleteNews(id: string | number): Promise<any> {
  const response = await api.delete(`/news/${id}`, { headers: { Authorization: getToken() } })
  return response.data
}

type EditNewsProps = {
  description: string
  urlPicture: string
  id: string | number
  weight: number
}

export async function editNews({
  description,
  urlPicture,
  id,
  weight
}: EditNewsProps): Promise<any> {
  const newsData = {
    description,
    url_picture: urlPicture,
    weight
  }
  const response = await api.put(`/news/${id}`, newsData, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

type NewsListType = {
  id: string | number
  weight: number
}

export async function updateNewsListWeight(newsList: NewsListType[]): Promise<any> {
  const response = await api.post('/news/weight', newsList, {
    headers: { Authorization: getToken() }
  })
  return response.data
}

// --------------------
// ARTICLE ADMIN APIs
// --------------------
type CreateArticleProps = {
  title: string
  content: string
  files: string[]
  formsAssociated: FormAssociatedModel[]
}

export async function createArticle(dto: CreateArticleProps): Promise<any> {
  const response = await api.post('/article', dto, { headers: { Authorization: getToken() } })
  return response.data
}

export async function getArticleList(page: number): Promise<ArticleListModel> {
  const response = await api.get(`/article?limit=${5}&page=${page}&order=DESC`, {
    headers: { Authorization: getToken() }
  })
  return response.data
}
export async function deleteArticle(id: string | number): Promise<any> {
  const response = await api.delete(`/article/${id}`, { headers: { Authorization: getToken() } })
  return response.data
}

export async function editArticle(id: string | number, dto: CreateArticleProps): Promise<any> {
  const response = await api.put(`/article/${id}`, dto, { headers: { Authorization: getToken() } })
  return response.data
}
