import { ClipLoader } from 'react-spinners'
import { WrapperSpinner } from './styles'

type Props = {
  size?: number
  marginTop?: string
}

const Spinner = ({ size = 45, marginTop = '3rem' }: Props) => {
  return (
    <WrapperSpinner $marginTop={marginTop}>
      <ClipLoader color="#002356" size={size} aria-label="Loading Spinner" data-testid="loader" />
    </WrapperSpinner>
  )
}

export default Spinner
