import { ModalContent } from '..'
import { ModalBodyInfo } from 'components/Modal/styles'

type Props = {
  toggleModal: () => void
  cardName: string
  formName: string
}

const NotificationModal = ({ toggleModal, cardName, formName }: Props) => {
  return (
    <ModalContent
      title="Notificação"
      handleCloseModal={toggleModal}
      handleSubmit={null}
      isLoading={false}
    >
      <ModalBodyInfo>
        <p className="info-form-text">
          Funcionalidade desativada, o card <b>{cardName}</b> possui o seguinte formulário:{' '}
          <b>{formName}</b>.
        </p>
      </ModalBodyInfo>
    </ModalContent>
  )
}

export default NotificationModal
