import { AttributeModel } from '../../../entities'

interface Props {
  data: Record<string, string | File>
  attributes: AttributeModel[]
}

export function formatDescriptionField ({ data, attributes }: Props) {
  const userName = JSON.parse(localStorage.getItem('user') as string).userName
  let newData: Record<string, string> = { 'Chamado foi aberto por: ': userName as string }
  const sortAttributeForm = attributes.sort(
    (a, b) => (a.positionOrder as number) - (b.positionOrder as number)
  )
  const dataResult = setNewData({ data, attributes: sortAttributeForm })

  dataResult.forEach((el) => {
    newData = { ...newData, ...el }
  })

  const description = turnObjectToString(newData)

  return description
}

interface SetNewDataProps extends Props {
  previewData?: any[]
}

const setNewData = ({ data, attributes, previewData = [] }: SetNewDataProps) => {
  const newData = previewData

  attributes.forEach((att) => {
    const objectData = buildNewData({ data, attributes: [att] })

    if (Object.keys(objectData).length > 0) {
      newData.push(objectData)
    }

    // Verify if exists extra questions inside the options
    if (att.optionsSelectElement?.length > 0) {
      att.optionsSelectElement.forEach((option) => {
        if (option.attributes.length > 0) {
          const sortAttributeOptions = option.attributes.sort(
            (a, b) => (a.positionOrder as number) - (b.positionOrder as number)
          )

          setNewData({
            data,
            attributes: sortAttributeOptions,
            previewData: newData
          })
        }
      })
    }
  })

  return newData
}

export const buildNewData = ({ data, attributes }: Props) => {
  let newData = {}

  attributes.forEach((attribute) => {
    for (const [key, value] of Object.entries(data)) {
      if (key === attribute.name && attribute.isVisibleToDescription) {
        const keyField = attribute.question
        const field: Record<string, string | string[]> = {}

        // Condition for Array<File> value
        if (Array.isArray(value) && value[0] instanceof File) {
          const filesName: string[] = []
          value.forEach((file) => {
            filesName.push(file.name)
          })

          field[keyField] = filesName

          newData = { ...newData, ...field }
          return
        }
        // Condition for File value
        if (!Array.isArray(value) && value instanceof File) {
          field[keyField] = value.name

          newData = { ...newData, ...field }
          return
        }

        field[keyField] = value
        newData = { ...newData, ...field }
      }
    }
  })

  return newData
}

export function turnObjectToString (data: Record<string, string>) {
  const result = Object.keys(data).reduce((prev, next) => {
    if (data[next] && Array.isArray(data[next])) {
      const arrayContent = data[next] as unknown as string[]
      const result = arrayContent.reduce((prev, next) => {
        return `${prev}, ${next}`
      })

      return `${prev} ${next} ${result}\n\n`
    }

    return `${prev} ${next} ${data[next] ?? 'Não informado'}\n\n`
  }, '')
  return result
}
