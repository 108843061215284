import { ReactNode } from 'react'
import Button from 'components/Button/Button'
import { ModalBody, ModalFooter, ModalHeader } from './styles'

type Props = {
  title: string
  handleCloseModal: () => void
  handleSubmit?: null | (() => void)
  isLoading: boolean
  children: ReactNode
  btnText?: string
  btnColor?: string
}

const ModalContent = ({
  title,
  handleCloseModal,
  isLoading,
  children,
  handleSubmit,
  btnText = 'Salvar',
  btnColor = '#008000'
}: Props) => {
  return (
    <>
      <ModalHeader>
        <span>{title}</span>
        <button onClick={handleCloseModal}>X</button>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      {handleSubmit && (
        <ModalFooter>
          <Button
            type="submit"
            isLoading={isLoading}
            text={btnText}
            color={btnColor}
            onClick={handleSubmit}
          />
        </ModalFooter>
      )}
    </>
  )
}

export default ModalContent
