import { AppRoutes } from './routes'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { GlobalStyle } from './styles/GlobalStyle'
import { ThemeProvider } from 'styled-components'
import { themes } from 'styles/themes'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

function App () {
  return (
    <ThemeProvider theme={themes}>
      <ToastContainer />
      <GlobalStyle />
      <AppRoutes />
    </ThemeProvider>
  )
}

export default App
library.add(fab, fas, far)
