import { useFormContext } from 'react-hook-form'
import { AttributeModel } from '../../../entities'

import { ErrorMessage, FormInputText } from './styles'

type Props = {
  attribute: AttributeModel
}

const EmailField = ({ attribute }: Props) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  const isRequired = attribute.required ?? false

  const emailValidation = (isRequired: boolean) => {
    const email = {
      pattern: {
        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        message: '* E-mail inválido'
      },
      required: {
        value: isRequired,
        message: '* Campo obrigatório'
      }
    }
    return email
  }
  return (
    <FormInputText>
      <label htmlFor={attribute.name}>{attribute.question}</label>
      <input
        type="email"
        placeholder={attribute.placeholder ?? 'exemplo@email.com'}
        {...register(attribute.name, emailValidation(isRequired))}
      />
      {errors[attribute.name] && (
        <ErrorMessage>{errors[attribute.name]?.message?.toString()}</ErrorMessage>
      )}
    </FormInputText>
  )
}

export default EmailField
