import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { INews } from 'entities'
import { updateNewsListWeight, getNewsList } from 'services/api'
import defaultNewsImage from '../assets/background-sebrae.jpg'

type GetNewsProps = {
  isOpenAddModal?: boolean
  isOpenDeleteModal?: boolean
  isOpenEditModal?: boolean
}

export const useGetNews = (props: GetNewsProps) => {
  const { isOpenAddModal, isOpenDeleteModal, isOpenEditModal } = props
  const [news, setNews] = useState<INews[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSort, setIsSort] = useState(false)

  const setOrderNewsList = async (newListReordered: INews[]) => {
    if (newListReordered.length > 0) {
      setIsLoading(true)
      const newList: Array<{ id: string | number, weight: number }> = []
      newListReordered.forEach((item, index) => newList.push({ id: item.id, weight: index }))
      try {
        const response = await updateNewsListWeight(newList)
        if (!response) {
          throw new Error()
        }
        // setIsSort(true)
        getNews()
      } catch (error) {
        toast.error('Ops 😥, ocorreu um erro ao reordenar os destques')
      } finally {
        setIsLoading(false)
      }
    }
  }

  const getNews = async () => {
    setIsLoading(true)
    try {
      const response = await getNewsList()
      if (!response) {
        throw new Error()
      }
      const newsListContent: INews[] = []
      response.forEach((news) => {
        newsListContent.push({
          id: news.id,
          weight: news.weight,
          description: news.description,
          imageUrl: news.url_picture,
          imageUrlData: news.url_picture ?? defaultNewsImage
        })
      })
      setNews(newsListContent)
    } catch (error) {
      toast.error('Ops 😥, ocorreu um erro ao carregar os destaques')
    } finally {
      setIsLoading(false)
    }
  }

  // useEffect(() => {
  //   if (!isOpenAddModal && !isOpenDeleteModal && !isOpenEditModal) {
  //     setIsSort(false)
  //     getNews()
  //   }
  // }, [isOpenAddModal, isOpenDeleteModal, isOpenEditModal])

  // useEffect(() => {
  //   if (isSort) {
  //     setIsSort(false)
  //     getNews()
  //   }
  // }, [isSort])

  return {
    news,
    isLoading,
    setOrderNewsList,
    getNews
  }
}
