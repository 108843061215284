import styled from 'styled-components'

export const ContainerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 2.3rem;
  background-color: ${({ theme }) => theme.colors.blue._500};
  border-radius: 0.5rem;
  border: none;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  transition: 0.2s;
  cursor: pointer;



  :disabled {
    cursor: not-allowed;
  }
`
