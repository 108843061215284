import { useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ModalContent } from 'components'
import { EditorContentField, ImageFileField } from 'components/TicketForms/Fields'
import { deleteFilePublic } from 'services/fileManager'
import { editNews } from 'services/api'
import { INews } from '../../entities'
import { getUrlFile, reactQuillContainerParams, validations } from './styles'

import { ModalBodyForm } from 'components/Modal/styles'

interface IFormInput {
  image: File | string
  content: string
}

type Props = {
  toggleModal: () => void
  newsSelected: INews
  getNews: () => void
}

const EditNewsModal = ({ toggleModal, newsSelected, getNews }: Props) => {
  const defaultValues = {
    content: newsSelected?.description,
    image: newsSelected?.imageUrl
  }
  const methods = useForm<IFormInput>({ defaultValues })
  const [isLoading, setIsLoading] = useState(false)

  const modules = useMemo(
    () => ({
      toolbar: {
        container: reactQuillContainerParams
      }
    }),
    []
  )

  const checkDataChanges = (data: IFormInput): boolean => {
    const isEqualImage = data.image === newsSelected.imageUrl
    const isEqualContent = JSON.stringify(data.content) === JSON.stringify(newsSelected.description)

    return !(isEqualImage && isEqualContent)
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    const hasChanges = checkDataChanges(data)
    if (!hasChanges) {
      toast.warn('Nada para editar!')
      toggleModal()
      return
    }
    if (!data.image) {
      methods.setError('image', { type: 'error', message: '* Campo obrigatório' })
      return
    }
    // Empty description
    if (data.content === '<p><br></p>') {
      methods.setError('content', { type: 'error', message: '* Campo obrigatório' })
      return
    }

    setIsLoading(true)
    let urlImage: string = newsSelected?.imageUrl
    if (data.image instanceof File) {
      urlImage = await getUrlFile(data.image)
    }
    try {
      const response = await editNews({
        description: data.content,
        urlPicture: urlImage,
        id: newsSelected.id,
        weight: newsSelected.weight
      })

      if (!response) {
        throw new Error()
      }
      if (urlImage !== newsSelected.imageUrl) {
        await deleteFilePublic(newsSelected.imageUrl)
      }
      setIsLoading(false)
      getNews()
    } catch (error) {
      setIsLoading(false)
      await deleteFilePublic(urlImage)
      toast.error('Ops 😥, ocorreu um erro ao editar o destaque')
    } finally {
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Editar destaque"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <ModalBodyForm>
          <ImageFileField
            attribute={{
              id: 1,
              name: 'image',
              question: '',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              defaultValue: null
            }}
            imageUrlData={newsSelected.imageUrlData}
          />
          <EditorContentField
            modulesEditor={modules}
            validations={validations}
            fieldName="content"
            label='Edite o texto'
          />
        </ModalBodyForm>
      </ModalContent>
    </FormProvider>
  )
}

export default EditNewsModal
