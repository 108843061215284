import styled from 'styled-components'
import { breakQueriesMaxMin } from 'styles/Breakpoints'

export const Container = styled.div<{ $height: string }>`
  width: 100vw;
  height: ${(props) => props.$height ?? '100vh'};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray._200};
  overflow-x: hidden !important;
`

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 6rem;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1rem 0rem;

  .header-logo {
    width: 10rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    img {
      width: 4rem;
      max-width: 4rem;
      height: 4rem;
      max-height: 4rem;
    }
  }

  .header-label {
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;

    p {
      font-size: 1.5rem;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.blue._800};
      text-transform: uppercase;
    }
  }

  .header-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0 auto;
    gap: 0.75rem;
    padding-right: 3rem;

    .header-info-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid ${({ theme }) => theme.colors.blue._500};
      border-radius: 50%;
      padding: 0.15rem;

      svg {
        font-size: 1rem;
        color: ${({ theme }) => theme.colors.blue._500};
      }
    }

    div > p:first-child {
      font-size: 1.25rem;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.blue._500};
    }
    div > p:last-child {
      color: ${({ theme }) => theme.colors.gray._450};
    }
  }
`

export const Sidebar = styled.nav`
  float: left;
  display: flex;
  flex-direction: column;
  width: 10rem;
  min-width: 10rem;
  max-width: 10rem;
  padding: 1.25rem 0 1.25rem 1.25rem;
  background-color: ${({ theme }) => theme.colors.blue._500};
  font-size: 0.875rem;

  a {
    text-decoration: none;
    text-transform: uppercase;
    padding: 0.5rem 0;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white};
    padding-left: 1rem;
    margin-bottom: 0.5rem;
    &:hover {
      text-decoration: underline;
    }
  }
  a.active {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blue._800};
    width: 100%;
    padding-left: 1rem;
    border-radius: 0.875rem 0 0 0.875rem;
  }

  a.link-bottom {
    margin-top: auto;
  }
`

export const WrapperContentSidebar = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;

  .wrapper-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2.5rem 3rem;
    width: 100%;
  }
`

export const BtnAdd = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.blue._800};
  color: ${({ theme }) => theme.colors.white};
  gap: 0.5rem;
  cursor: pointer;
  transition-duration: 0.2s;

  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    cursor: not-allowed;
  }

  p:first-child {
    padding: 0rem 0.15rem;
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-radius: 0.175rem;
  }
`

export const WrapperSpinner = styled.div<{ $marginTop?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 3rem;
  margin-top: ${(props) => props.$marginTop ?? '3rem'};
`
