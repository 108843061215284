/* eslint-disable @typescript-eslint/naming-convention */
import { ReactNode, createContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { authenticate } from '../services/authApi'
import { createSession } from '../services/api'
import { getUserProfile } from '../services/connector'

export type PropsContext = {
  signIn: (login: string, password: string) => void
  errorMessage: string
  isLoading: boolean
  logout: () => void
}

type SaveInLocalStorageProps = {
  token: string
  userLogin: string
  userName: string
  role: string
  user_department: string | null
}

type Props = {
  children: ReactNode
}

const initialState = {
  signIn: () => { },
  errorMessage: '',
  logout: () => { }
}

export const AuthContext = createContext<PropsContext>(initialState as unknown as PropsContext)

export const AuthProvider = ({ children }: Props) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const saveDataInLocalStorage = async (respUserProfile: any, props: SaveInLocalStorageProps) => {
    const { token, userName, userLogin, role, user_department } = props
    localStorage.setItem('token', token)

    try {
      if (!respUserProfile) {
        throw new Error('Request failed with status code 500')
      }
      const user = {
        userLogin,
        userName,
        role,
        user_department
      }
      localStorage.setItem('user', JSON.stringify(user))
    } catch (error) {
      setErrorMessage('Usuário não encontrado na base do ITSM. por favor entre em contato com a central de serviços.')
      localStorage.setItem('isHidden', 'false')
    }
  }

  const signIn = async (username: string, password: string) => {
    setIsLoading(true)

    try {
      setErrorMessage('') // Reset error message
      const respUserProfile = await getUserProfile(username) // Busca do usuário na base de dados do ITSM

      const respUser = await authenticate(username, password) // Autenticação com usuário e senha do AD
      if (!respUser) {
        throw new Error('Request failed with status code 401' || 'Request failed with status code 404')
      }
      const respSession = await createSession(respUser.user_full_name, respUser.user_name) // Abre sessão do usuário
      if (!respSession) {
        throw new Error()
      }

      await saveDataInLocalStorage(respUserProfile, { // Utiliza os dados da requisição do usuário para salvar informações
        token: respSession.token,
        userLogin: respUser.user_name,
        userName: respUser.user_full_name,
        role: respSession?.user?.roles,
        user_department: respUser.user_department
      })

      navigate('/')
      setIsLoading(false)
    } catch (error: any) { // De acordo com cada requisição, o erro pode mudar
      const errorMessages: Record<string, string> = {
        'Request failed with status code 401': 'Usuário ou senha incorretos!',
        'Request failed with status code 404': 'Usuário ou senha incorretos!',
        'Request failed with status code 500': 'Usuário não encontrado na base do ITSM. Por favor, entre em contato com a central de serviços.'
      }

      setErrorMessage(errorMessages[error.message] || 'Erro desconhecido ao fazer login!')
      setIsLoading(false)
    }
  }

  const logout = async () => {
    window.location.reload()
    localStorage.clear()
    sessionStorage.clear()
    navigate('/Login')
  }

  return (
    <AuthContext.Provider
      value={{
        signIn,
        isLoading,
        errorMessage,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
