import { useLocation, Link } from 'react-router-dom'
import HamburgerMenu from './HamburgerMenu'
import { LinkProps } from '.'

import { HeaderMenuBar, HeaderMenuLink } from './styles'

type Props = {
  items: LinkProps[]
}

const HeaderMenu = ({ items }: Props) => {
  const { pathname } = useLocation()
  const links = items.slice(0, 2)
  const hasAdmin = items.find((item) => item.title === 'Administrador')
  // const hasAdmin = items.find((item) => item.title === 'Administrador')
  return (
    <HeaderMenuBar>
      {links.map((link, index) => (
        <HeaderMenuLink
          key={link.href}
          $isActive={pathname === link.href}
          className={index === links.length - 1 ? '' : 'link-with-border'}
        >
          <Link to={link.href}>
            {link.title} <span />
          </Link>
        </HeaderMenuLink>
      ))}
      <HamburgerMenu items={
          hasAdmin ? [
            { title: 'Administrador', href: '/admin' },
            { title: 'Sair', href: '/Login' }
          ] : [{ title: 'Sair', href: '/Login' }]
        }/>
    </HeaderMenuBar>
  )
}

export default HeaderMenu
