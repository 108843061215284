import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { ModalContent } from 'components'
import { DeleteAdmin } from 'services/api'
import { UserAdminModel } from '../../entities'
import { AuthContext } from 'contexts/auth'

import { ModalBodyDelete } from 'components/Modal/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

type Props = {
  toggleModal: () => void
  adminSelected: UserAdminModel | null
  getAdmins: () => void
}

const DeleteAdminModal = ({ toggleModal, adminSelected, getAdmins }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const { logout } = useContext(AuthContext)
  const handleDeleteAdmin = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await DeleteAdmin(adminSelected?.id as string)
      if (!response) {
        throw new Error()
      }
      const user = JSON.parse(localStorage.getItem('user') as string)
      setIsLoading(false)
      getAdmins()
      if (user.userLogin.toLowerCase() === adminSelected?.userLogin.toLowerCase()) {
        toast.error('Ops 😥, você não é mais administrador, por favor fazer login novamente')
        setTimeout(() => {
          logout()
        }, 2000)
      }
    } catch (error) {
      setIsLoading(false)
      toast.error('Ops 😥, ocorreu um erro ao remover os dados do administrador')
    } finally {
      toggleModal()
    }
  }

  return (
    <ModalContent
      title="Remover administrador"
      handleCloseModal={toggleModal}
      handleSubmit={handleDeleteAdmin}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor="#cf1f3f"
    >
      <ModalBodyDelete>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
          <p>Deseja remover este administrador?</p>
        </div>
        <p className="admName">{adminSelected?.name}</p>
      </ModalBodyDelete>
    </ModalContent>
  )
}

export default DeleteAdminModal
