import { Controller, useFormContext } from 'react-hook-form'
import { AttributeModel } from '../../../entities'

import { ErrorMessage, FormInputPhone } from './styles'

const phoneMask = (value: any) => {
  const onlyNumber = value.replace(/\D/g, '')
  /* Fixed phone without DDD */
  if (onlyNumber.length === 8) {
    const phoneFormatted = onlyNumber
    return phoneFormatted.replace(/(\d)(\d{4})$/, '$1-$2')
  }
  /* Fixed phone with DDD */
  if (onlyNumber.length === 10) {
    const phoneFormatted = onlyNumber
    return phoneFormatted
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2')
  }
  /* Mobile phone without DDD */
  if (onlyNumber.length === 9) {
    const phoneFormatted = onlyNumber
    return phoneFormatted
      .replace(/(\d)(\d{4})$/, '$1-$2')
  }

  /* Mobile phone with DDD */
  if (onlyNumber.length === 11) {
    const phoneFormatted = onlyNumber
    return phoneFormatted
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2')
  }

  return onlyNumber
}

type Props = {
  attribute: AttributeModel
}

const PhoneField = ({ attribute }: Props) => {
  const {
    control,
    getValues,
    formState: { errors }
  } = useFormContext()
  const isRequired = attribute.required ?? false

  return (
    <Controller
      name={attribute.name}
      control={control}
      rules={{ required: isRequired, minLength: 9 }}
      render={({ field: { onChange } }) => (
        <FormInputPhone>
          <label htmlFor={attribute.name}>{attribute.question}</label>
          <input
            type="tel"
            maxLength={15}
            placeholder="(00) 0000-0000"
            value={getValues()[attribute.name] ?? ''}
            onChange={(e) => {
              onChange(phoneMask(e.target.value))
            }}
          />
          {errors[attribute.name]?.type === 'required' && (
            <ErrorMessage>* Campo Obrigatório</ErrorMessage>
          )}
          {errors[attribute.name]?.type === 'minLength' && (
            <ErrorMessage>* Deve ser um telefone válido</ErrorMessage>
          )}
        </FormInputPhone>
      )}
    />
  )
}

export default PhoneField
