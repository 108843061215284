import { useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ModalContent } from 'components'
import { EditorContentField, ImageFileField } from 'components/TicketForms/Fields'
import { deleteFilePublic } from 'services/fileManager'
import { createNews } from 'services/api'
import { getUrlFile, reactQuillContainerParams, validations } from './styles'

import { ModalBodyForm } from 'components/Modal/styles'

interface IFormInput {
  image: File
  content: string
}

type Props = {
  toggleModal: () => void
  listLength: number
  getNews: () => void
}

const AddNewsModal = ({ toggleModal, listLength, getNews }: Props) => {
  const methods = useForm<IFormInput>()
  const [isLoading, setIsLoading] = useState(false)
  const modules = useMemo(
    () => ({
      toolbar: {
        container: reactQuillContainerParams
      }
    }),
    []
  )

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    if (data.content === '<p><br></p>') {
      methods.setError('content', { type: 'error', message: '* Campo obrigatório' })
      return
    }
    setIsLoading(true)
    const urlImage = await getUrlFile(data.image)
    try {
      const response = await createNews(data.content, urlImage, listLength + 1)
      if (!response) {
        throw new Error()
      }
      getNews()
    } catch (error) {
      await deleteFilePublic(urlImage)
      toast.error('Ops 😥, ocorreu um erro ao adicionar o destaque')
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  useEffect(() => {
    if (listLength === 10) {
      toast.error('Já existem 10 destaques!')
      handleCloseModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listLength])

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Novo destaque"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <ModalBodyForm>
          <ImageFileField
            attribute={{
              id: 1,
              name: 'image',
              question: '',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              defaultValue: null
            }}
          />
          <EditorContentField
            modulesEditor={modules}
            validations={validations}
            fieldName="content"
            label='Adicione um texto'
          />
        </ModalBodyForm>
      </ModalContent>
    </FormProvider>
  )
}

export default AddNewsModal
