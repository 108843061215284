import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { getFormByUuid } from '../services/api'
import { FormI } from '../entities'

export const useGetForm = (formId: string) => {
  const [form, setForm] = useState<FormI | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const flagRenderOnce = useRef(true)

  const getForm = async () => {
    setIsLoading(true)
    try {
      const response = await getFormByUuid(formId)
      if (!response) {
        throw new Error()
      }
      const labelName = response.ancestorsCardTreeName ? response.ancestorsCardTreeName.split('>')[0] : response.associatedCard.name

      setForm({
        id: response.id,
        title: response.title,
        isEnable: response.isEnable,
        category: response.categories[0] ?? {},
        attributes: response.attributes,
        color: response.associatedCard.color,
        parentName: labelName,
        articles: response.articles ?? [],
        parentCard: response.associatedCard?.name
      })
    } catch (error) {
      toast.error('Ops 😥, ocorreu um erro ao recuperar o formulário ')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (flagRenderOnce.current) {
      flagRenderOnce.current = false
      getForm()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId])

  return {
    isLoadingForm: isLoading,
    form
  }
}
