import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ModalContent } from 'components'
import { deleteMultiplePublicFile, getMultiplePublicFile } from 'services/fileManager'
import { OptionSelect } from 'components/Select/Select'
import { ArticleModel, FormAssociatedModel } from '../../entities'
import { editArticle } from 'services/api'
import { EditorContentField, SelectField, TextField } from 'components/TicketForms/Fields'

import { ModalBodyForm } from 'components/Modal/styles'
import { customSelectStyles } from './styles'

interface IFormInput {
  formsAssociated: FormAssociatedModel[]
  content: string
  title: string
}

export type ImageProps = {
  fileName: string
  index: number
  base64: string
}
type Props = {
  toggleModal: () => void
  formOptions: OptionSelect[]
  articleSelected: ArticleModel
  formOptionsSelected: OptionSelect[]
  getArticles: () => void
  isLoadingFormsAssociated: boolean
}

const EditArticleModal = ({
  toggleModal,
  formOptions,
  articleSelected,
  formOptionsSelected,
  getArticles,
  isLoadingFormsAssociated
}: Props) => {
  const defaultValues: IFormInput = {
    title: articleSelected.title,
    content: articleSelected.content,
    formsAssociated: articleSelected.formsAssociated
  }
  const methods = useForm<IFormInput>({ defaultValues })
  const [isLoading, setIsLoading] = useState(false)

  const [images, setImages] = useState<ImageProps[]>([])
  const [files, setFiles] = useState<File[]>([])

  const replaceBase64ByUrl = (
    content: string,
    urlList: Array<{ name: string, url: string }>
  ): string => {
    let newValue = ''

    if (images.length > 0) {
      let auxNewValue = content
      images.forEach(({ fileName, base64 }) => {
        const newUrl = urlList.find((url: any) => url.name === fileName)
        if (newUrl) {
          newValue = auxNewValue
          newValue = newValue.replace(base64, newUrl?.url)
          auxNewValue = newValue
        }
      })
    }
    return newValue
  }

  const getUrlsImage = async (): Promise<Array<{ name: string, url: string }>> => {
    const urlList: Array<{ name: string, url: string }> = []
    if (files.length > 0) {
      const response = await getMultiplePublicFile(files)
      if (!response) throw new Error()
      response.forEach((file: any) =>
        urlList.push({ name: file.data[0].originalname, url: file.data[0].name })
      )
    }
    return urlList
  }

  const checkDataChanges = (data: IFormInput): boolean => {
    const isEqualTitle = data.title === articleSelected.title
    const isEqualContent = JSON.stringify(data.content) === JSON.stringify(articleSelected.content)
    const isEqualFormIds =
      JSON.stringify(methods.getValues('formsAssociated')) ===
      JSON.stringify(articleSelected.formsAssociated)

    return !(isEqualContent && isEqualFormIds && isEqualTitle)
  }

  const buildImageList = (content: string, urlList: string[]) => {
    const newList: string[] = []
    const filesToRemove: string[] = []
    if (articleSelected.files && articleSelected.files?.length > 0) {
      articleSelected.files?.forEach((img) => {
        if (content.includes(img)) {
          newList.push(img)
        } else {
          filesToRemove.push(img)
        }
      })
    }

    if (urlList.length > 0) {
      urlList.forEach((url) => newList.push(url))
    }
    return { newList, filesToRemove }
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    const hasChanges = checkDataChanges(data)
    if (!hasChanges) {
      toast.warn('Nada para editar!')
      toggleModal()
      return
    }

    if (data.content === '<p><br></p>') {
      methods.setError('content', { type: 'error', message: '* Campo obrigatório' })
      return
    }
    setIsLoading(true)
    let urlList = []
    const list: string[] = []
    try {
      urlList = await getUrlsImage()
      urlList.forEach((el) => list.push(el.url))
      const newContent = replaceBase64ByUrl(data.content, urlList)
      const { newList, filesToRemove } = buildImageList(data.content, list)
      const dto = {
        id: articleSelected.id,
        title: data.title,
        content: newContent || data.content,
        files: newList,
        formsAssociated: data.formsAssociated
      }
      const response = await editArticle(articleSelected.id, dto)
      if (!response) {
        throw new Error()
      }

      if (filesToRemove.length > 0) {
        await deleteMultiplePublicFile(filesToRemove)
      }
      setIsLoading(false)
      getArticles()
    } catch (error) {
      if (urlList.length > 0) {
        await deleteMultiplePublicFile(list)
      }
      setIsLoading(false)
      toast.error('Ops 😥, ocorreu um erro ao adicionar a FAQ')
    } finally {
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Editar FAQ"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <ModalBodyForm>
          <SelectField
            attribute={{
              id: 0,
              name: 'formsAssociated',
              question: 'Qual formulário esta Faq estará relacionada?',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              placeholder: 'Adicione o título...',
              defaultValue: null
            }}
            options={formOptions}
            isLoadingFormsAssociated={isLoadingFormsAssociated}
            menuPlacement={'bottom'}
            customSelectStyles={customSelectStyles}
            isMulti={true}
            defaultValue={formOptionsSelected}
          />

          <TextField
            attribute={{
              id: 1,
              name: 'title',
              question: 'Adicione o título:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              placeholder: 'Adicione o título...',
              defaultValue: null
            }}
          />
          <EditorContentField
            fieldName="content"
            validations={{
              required: {
                value: true,
                message: '* Campo obrigatório'
              }
            }}
            images={images}
            setImages={setImages}
            files={files}
            setFiles={setFiles}
            value={articleSelected.content}
            label="Edite o conteúdo da faq"
          />
        </ModalBodyForm>
      </ModalContent>
    </FormProvider>
  )
}

export default EditArticleModal
