/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { deleteFile } from '../../services/fileManager'
import { storeTicket } from 'services/connector'
import { AttributeModel, AttributeOptionModel, FormI } from '../../entities'
import { InputsToRender } from './InputsToRender'

import { Button } from '..'
import {
  formatDescriptionField,
  getCategoryInfoFromSelectedOption,
  getExtraQuestions,
  getUserField,
  setExtraQuestionsFromCheckboxField,
  setExtraQuestionsFromSelectField,
  setFileField,
  updateUserAnswer,
  setEquipmentSelected
} from './util'
import { Form } from './styles'

export interface OptionListI extends AttributeOptionModel {
  attName: string
  firstLevel?: boolean
}

type Props = {
  form: FormI
}

const FormGeneric = ({ form }: Props) => {
  const methods = useForm()
  const navigate = useNavigate()
  const isVip = localStorage.getItem('isHidden') === 'true'
  const [isLoading, setIsLoading] = useState(false)
  const [userName, setUserName] = useState('')
  const [extraQuestions, setExtraQuestions] = useState<AttributeModel[] | null>(null)
  const [optionListSelected, setOptionListSelected] = useState<OptionListI[]>([])
  const [equipments, setEquipment] = useState<Record<string, number>>({})
  const user = JSON.parse(localStorage.getItem('user') as string)

  const onSubmitForm = async (data: Record<string, string | File>) => {
    setIsLoading(true)
    const attachmentsGLPI = await setFileField(data)

    try {
      const categoryData = getCategoryInfoFromSelectedOption(optionListSelected)
      const { question, answer } = getUserField(data, form)

      const newDataForDescription = updateUserAnswer(data, question, userName)

      const description = formatDescriptionField({
        data: newDataForDescription,
        attributes: form.attributes
      })
      const type = categoryData?.type ?? form.category?.type

      const dto = {
        user_called: user.userLogin,
        user_login: answer,
        status: '1' as const,
        ticket_category_id: (categoryData?.category_id ?? form.category?.category_id) as string,
        group: (categoryData?.group ?? form.category?.group) as string,
        ticket_content: description,
        ticket_name: categoryData?.title ?? form.title,
        ticket_type: type ? ('2' as const) : ('1' as const),
        created_via: '3',
        attachments_glpi: attachmentsGLPI
      }

      if (user.userName === answer) {
        dto.user_called = null
      }

      const response = await storeTicket(dto)
      if (!response) {
        throw new Error()
      }
      setIsLoading(false)
      navigate(`/ticket/success/${response.ticket_id}`, {
        state: { user: dto.user_login }
      })
    } catch (error: any) {
      setIsLoading(false)

      if (attachmentsGLPI && attachmentsGLPI.length > 0) {
        const urlList: any = []
        attachmentsGLPI?.forEach((el) => urlList.push({ name: el.file_url }))
        await deleteFile(urlList)
      }
      if (error?.response?.data?.err?.message === 'username inválido') {
        toast.error('Seu usuário ainda não foi replicado no sistema de chamado, por favor aguarde')
      } else {
        toast.error('Ops 😥, ocorreu um erro ao abrir o chamado')
      }
    }
  }

  useEffect(() => {
    if (form?.attributes?.length > 0) {
      const sortAttributeForm = form.attributes.sort(
        (a, b) => (a.positionOrder as number) - (b.positionOrder as number)
      )
      sortAttributeForm.forEach((attribute) => {
        if (!(attribute.isHiddenToVip && isVip)) {
          methods.unregister(attribute.name)
        }
      })
      setExtraQuestions(getExtraQuestions(form.attributes))
    }
  }, [form])

  const handleCheckboxExtraQuestions = (
    e: ChangeEvent<HTMLInputElement>,
    option: AttributeOptionModel,
    attName: string
  ) => {
    const newOptionListSelected = setExtraQuestionsFromCheckboxField({
      optSelected: option,
      isChecked: e.target.checked,
      attName,
      optionListSelected,
      methods
    })
    setOptionListSelected(newOptionListSelected)
  }

  const handleSelectExtraQuestions = (
    optSelected: string,
    attribute: AttributeModel,
    firstLevel = false
  ) => {
    const newOptionListSelected = setExtraQuestionsFromSelectField({
      optSelected,
      attribute,
      optionListSelected,
      methods,
      firstLevel
    })
    setOptionListSelected(newOptionListSelected)
  }

  const handleEquipmentSelection = (option: string, quantity: number) => {
    const list = setEquipmentSelected({ option, quantity, equipments })
    setEquipment(list)
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmitForm)} $color={form.color}>
        {form?.parentName && (
          <div className="form-label">
            <span>ATENDIMENTO PARA:</span>
            <span>{form.parentName}</span>
          </div>
        )}

        <h2>Preencha os campos abaixo </h2>

        <div className="form-inputs">
          <InputsToRender
            attributes={form.attributes}
            isVip={isVip}
            setUserName={setUserName}
            handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
            handleSelectExtraQuestions={handleSelectExtraQuestions}
            optionListSelected={optionListSelected}
            handleEquipmentSelection={handleEquipmentSelection}
            firstLevel={true}
          />
        </div>

        <div className="form-button">
          <Button type="submit" isLoading={isLoading} text="Enviar" />
        </div>
      </Form>
    </FormProvider>
  )
}

export default FormGeneric
