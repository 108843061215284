/* eslint-disable react-hooks/exhaustive-deps */
import { AuthContext } from 'contexts/auth'
import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { GetNTickets, TicketI } from '../entities'
import { getNTickets } from '../services/connector'

export const formatTicketListData = (list: GetNTickets[]) => {
  const dataFormatted = [] as TicketI[]
  list.forEach((item: GetNTickets) => {
    dataFormatted.push({
      ticketID: item.ticket_id ?? 'ID inválido, por favor, entre em contato conosco.',
      title: item.ticket_title ?? 'Chamado sem título',
      date: item.ticket_date ?? 'Data do chamado é desconhecido',
      status: item.ticket_status ?? 'Chamado sem status'
    })
  })
  return dataFormatted
}

export const useGetTickets = (maxTickets = 12, status = '') => {
  const [ticketList, setTicketList] = useState<[] | TicketI[]>([])
  const [isLoadingTickets, setLoadingTickets] = useState(false)
  const { logout } = useContext(AuthContext)

  const getTicketList = async () => {
    setLoadingTickets(true)
    try {
      if (!localStorage.getItem('user')) {
        toast.error(
          'Ops 😥, houve um erro com os dados do usuário, por favor fazer login novamente'
        )
        setTimeout(() => {
          logout()
        }, 2000)
        return
      }

      const userLogin = JSON.parse(localStorage.getItem('user') as string).userLogin

      const response = await getNTickets(userLogin, maxTickets, status)
      if (!response) {
        setTicketList([])
        return
      }
      const ticketListFormatted = formatTicketListData(response)
      setTicketList(ticketListFormatted)
    } catch (error) {
      toast.error('Ops 😥, ocorreu um erro ao recuperar os chamados ')
    } finally {
      setLoadingTickets(false)
    }
  }

  useEffect(() => {
    getTicketList()
  }, [status, maxTickets])

  return {
    ticketList,
    isLoadingTickets
  }
}
