import { useFormContext } from 'react-hook-form'

import { ErrorMessage, FormInputText } from './styles'
import { useEffect } from 'react'
import { AttributeModel } from '../../../entities'
import { useGetUserData } from 'hooks'

type Props = {
  attribute: AttributeModel
}

const TextField = ({ attribute }: Props) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch
  } = useFormContext()
  const { getData } = useGetUserData()

  const validations = {
    required: {
      value: attribute.required ?? false,
      message: '* Campo obrigatório'
    },
    maxLength: {
      value: attribute.maxLength ?? 25000,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      message: `* Máximo ${attribute.maxLength ?? 25000} caracteres permitidos`
    }
  }

  useEffect(() => {
    if (attribute.defaultValue) {
      const _defaultValue = attribute.defaultValue.split('.')
      const userData = getData(_defaultValue[0])
      if (userData[_defaultValue[1]]) {
        setValue(attribute.name, userData[_defaultValue[1]])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attribute])

  return (
    <FormInputText>
      <label htmlFor={attribute.name}>{attribute.question}</label>
      <input
        type="text"
        placeholder={attribute.placeholder ?? ''}
        {...register(attribute.name, validations)}
      />
      {errors[attribute.name] && (
        <ErrorMessage>{errors[attribute.name]?.message?.toString()}</ErrorMessage>
      )}
    </FormInputText>
  )
}

export default TextField
